/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  query,
  where,
  orderBy,
  limit,
  collection,
  doc,
} from 'firebase/firestore';
// Paths
import { documentsPaths } from 'src/configs';
// Types
import { TypeDocumentView, TypeDocumentStatus } from 'src/@types';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //
/**
 * @description - The method is generating the reference of the collection
 * @param documentType
 */
function getCollectionNameByDocumentType(documentType: TypeDocumentView) {
  switch (documentType) {
    case "document":
      return documentsPaths.COLLECTION_DOCUMENTS;
    case "contract":
      return documentsPaths.COLLECTION_CONTRACTS;
    case "act":
      return documentsPaths.COLLECTION_ACTS;
    default:
      return 'none';
  }
}

/**
 * @description - The method is generating the reference of the collection
 * @param documentType
 */
export function generateCollectionReference(documentType: TypeDocumentView) {
  const collectionPath = getCollectionNameByDocumentType(documentType);
  // The document reference in which the query should do fetching
  return collection(getFirestore(), collectionPath);
}

/**
 * @description - The method is generating the reference of the collection
 * @param documentType
 */
export function generateDocumentReference(documentType: TypeDocumentView, documentUid?: string) {
  const collectionPath = getCollectionNameByDocumentType(documentType);
  // If document uid defined then return reference  of the exact document
  if (documentUid) {
    return doc(collection(getFirestore(), collectionPath), documentUid);
  }
  // The document reference in which the query should do fetching
  return doc(collection(getFirestore(), collectionPath));
}

export function generateMailReference() {
  return doc(collection(getFirestore(), 'mail'));
}

/**
 * @description - The method is generating firestore query in order to fetch contracts/acts/documents from appropriate
 * collection.
 * @param companyUid - The company uid which project is related to.
 * @param projectUid - The project uid which documents should be selected.
 * @param userUid - The user uid which is doing the request
 * @param documentType - The document type (contract/act/document) which need to fetch
 * @param documentStatusType - The document status type - Template/Processing/Archive/Deleted
 * @param limitNumber - The result limit in order to make it faster
 * @param pageNumber - The pagination
 */
export function queryFetchAllDocumentsByProjectUid(
  userUid: string,
  companyUid: string,
  projectUid: string,
  documentType: TypeDocumentView,
  documentStatusType: TypeDocumentStatus,
  status?: string,
  limitNumber?: number,
  pageNumber?: number,
) {
  // The document reference in which the query should do fetching
  const documentsDbReference = generateCollectionReference(documentType);
  // Returning the query
  let finalQuery = query(
    documentsDbReference,
    // Checking company based documents
    where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking project based documents
    // where(documentsPaths.FIELD_PROJECT_UID, '==', projectUid),
    // ToDo add permission based query (permission groups)
    // where()
    // Checking status based documents - processing/template/archive/removed
    where(documentsPaths.FIELD_DOCUMENT_STATUS, '==', documentStatusType),
    // Visibility
    where(documentsPaths.FIELD_VISIBLE, '==', true),
    // Ordering by created date
    orderBy(documentsPaths.FIELD_CREATED, 'desc'),
    // If no limit provided the default is 25
    limit(limitNumber ?? 25),
  );

  if (status) {
    finalQuery = query(
      documentsDbReference,
      // Checking company based documents
      where(documentsPaths.FIELD_COMPANY_UID, '==', companyUid),
      // Checking project based documents
      where(documentsPaths.FIELD_PROJECT_UID, '==', projectUid),
      // ToDo add permission based query (permission groups)
      where('status', '==', status),
      // Checking status based documents - processing/template/archive/removed
      where(documentsPaths.FIELD_DOCUMENT_STATUS, '==', documentStatusType),
      // Visibility
      where(documentsPaths.FIELD_VISIBLE, '==', true),
      // Ordering by created date
      orderBy(documentsPaths.FIELD_CREATED, 'desc'),
      // If no limit provided the default is 25
      limit(limitNumber ?? 25),
    );
  }

  return finalQuery;
}

export function queryDeviceUid(
  userUid: string,
) {
  // The document reference in which the query should do fetching
  const documentsDbReference = generateCollectionReference('contract');
  // Returning the query
  return query(
    documentsDbReference,
    // Checking company based documents
    where('assigned_device', '==', userUid),
    where('active', '==', true),
    where('visible', '==', true),
    where("statusProcessStage", "in", ["show", "sign", "confirm"])
    // Checking project based documents
  );
}

export function queryRtarmeniaTemplates() {
  // The document reference in which the query should do fetching
  const documentsDbReference = generateCollectionReference('rttemplates');
  // Returning the query
  return query(
    documentsDbReference,
    // Checking company based documents
    where('active', '==', true),
    where('visible', '==', true),
    // Checking project based documents
  );
}
