/**
 * @description - The all services that are related to the project's data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getDocs,
  getDoc, doc, getFirestore,
} from 'firebase/firestore';
// Queries
import {
  queryFetchAllProjectsByPlatform,
  queryTeam,
  queryFetchAllTemplatesByProjectUid,
  queryFetchAllTemplatesRTByProjectUid
} from 'src/services/projects/queries';
// Local type
import { TypePlatforms, ModelProject } from 'src/@types';
import { userPaths } from "src/configs";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export function getTeam(
  onSuccess: (records: Record<string, any>[]) => void
) {
  const queryRef = queryTeam();
  getDocs(queryRef)
    .then((querySnapshot) => {
      const listOfPlatformBasedProjects: Record<string, any>[] = [];
      querySnapshot.forEach((projectSnapshot) => {
        const projectServerData = projectSnapshot.data();
        if (projectServerData) {
          listOfPlatformBasedProjects.push(projectServerData)
        }
      });
      onSuccess(listOfPlatformBasedProjects);
    })
    .catch((error) => console.log(error.message));

}

/**
 * @description - The method is fetching all projects that are related to the appropriate user.
 * @param platform - The project type that needs to fetch for the specific platform
 * @param onSuccess - On success method, is executing whenever the method completed successfully. Passing the result
 * list to the void function that will be used in the parent.
 * @param onFail - On failed method, is executing whenever there is any issue during the data fetching
 * @param companyUid - The selected company UID,
 * @param userUid - The selected user uid which is doing the fetching process
 */
export function fetchAllProjectsByPlatform(
  platform: TypePlatforms,
  onSuccess: (allProjects: ModelProject[]) => void,
  onFail: (error: Error | string) => void,
  companyUid?: string,
  userUid?: string,
) {
  // Only if the user exists and the user correspond to any company
  if (userUid && companyUid) {
    const queryAllProjectsByPlatform = queryFetchAllProjectsByPlatform(
      userUid,
      companyUid,
      platform,
    );
    // Trying to fetch the request forms
    getDocs(queryAllProjectsByPlatform)
      .then((querySnapshot) => {
        const listOfPlatformBasedProjects: ModelProject[] = [];
        querySnapshot.forEach((projectSnapshot) => {
          const projectServerData = projectSnapshot.data();
          if (projectServerData) {
            listOfPlatformBasedProjects.push({
              uid: projectServerData.uid ?? 'N/A',
              company_uid: projectServerData.company_uid ?? 'N/A',
              manager_uid: projectServerData.manager_uid ?? 'N/A',
              display_name: projectServerData.display_name ?? 'N/A',
              permissions: projectServerData.permissions ?? [],
              project_types: projectServerData.project_types ?? [],
              active: projectServerData.active ?? false,
              visible: projectServerData.visible ?? false,
              status: projectServerData.status ?? 'N/A',
              created: projectServerData.created ?? null,
              updated: projectServerData.updated ?? null,
            })
          }
        });
        onSuccess(listOfPlatformBasedProjects);
      })
      .catch((error) => onFail(error.message));
  } else {
    // If the user.uid is not defined or the user company is empty
    onFail('User or company is not defined');
  }
}

export function fetchAllContractTemplatesByProjectUid(
  project_uid: string,
  onSuccess: (data: Record<string, any>[]) => void
) {
  const queryAllProjectsByPlatform = queryFetchAllTemplatesByProjectUid(project_uid);
  getDocs(queryAllProjectsByPlatform)
    .then((querySnapshot) => {
      const listOfPlatformBasedProjects: Record<string, any>[] = [];
      querySnapshot.forEach((projectSnapshot) => {
        const projectServerData = projectSnapshot.data();
        if (projectServerData) {
          listOfPlatformBasedProjects.push({
            ...projectServerData
          });
        }
      });
      onSuccess(listOfPlatformBasedProjects);
    })
    .catch((error) => console.log(error.message));
}

export function fetchAllContractTemplatesRTByProjectUid(
  project_uid: string,
  onSuccess: (data: Record<string, any>[]) => void
) {
  const queryAllProjectsByPlatform = queryFetchAllTemplatesRTByProjectUid(project_uid);
  getDocs(queryAllProjectsByPlatform)
    .then((querySnapshot) => {
      const listOfPlatformBasedProjects: Record<string, any>[] = [];
      querySnapshot.forEach((projectSnapshot) => {
        console.log('here');
        const projectServerData = projectSnapshot.data();
        if (projectServerData) {
          listOfPlatformBasedProjects.push({
            ...projectServerData
          });
        }
      });
      onSuccess(listOfPlatformBasedProjects);
    })
    .catch((error) => console.log(error.message));
}

export function getTemplateData(
  template_uid: string,
  onSuccess: (data: Record<string, any>) => void
) {
  const userDbReference = doc(
    getFirestore(),
    'contract_templates',
    template_uid
  );
  getDoc(userDbReference)
    .then((doc) => {
      if (doc.exists()) {
        const userProfileDocument = doc.data();
        onSuccess(userProfileDocument);
      }
    })
    .catch((error) => {
      console.error(error);})
}

export function getTemplateRTData(
  template_uid: string,
  onSuccess: (data: Record<string, any>) => void
) {
  const userDbReference = doc(
    getFirestore(),
    'none',
    template_uid
  );
  getDoc(userDbReference)
    .then((doc) => {
      if (doc.exists()) {
        const userProfileDocument = doc.data();
        onSuccess(userProfileDocument);
      }
    })
    .catch((error) => {
      console.error(error);})
}
